import { template as template_0cc1f657fbf1411c8d5b0758e51aa1eb } from "@ember/template-compiler";
const FKLabel = template_0cc1f657fbf1411c8d5b0758e51aa1eb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
