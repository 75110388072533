import { template as template_5c1029a71b0b4665b1f40888fe296e4b } from "@ember/template-compiler";
const FKText = template_5c1029a71b0b4665b1f40888fe296e4b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
